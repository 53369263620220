
import { Vue, Component, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiPCSettings, apiPCSettingsSet } from '@/api/channel/pc_store'
import { copyClipboard } from '@/utils/util'
@Component({
    components: {
        MaterialSelect
    }
})
export default class h5Store extends Vue {
    /** S Data **/
    form = {
        status: 0, // 渠道状态 0-关闭 1-开启
        redirect_type: 0, // 跳转类型 0-跳转至空白页 1-跳转至指定页
        redirect_content: '', // 跳转内容 跳转至空白页时是提示文字，跳转至指定页时是页面链接
        title: '', // 网站标题
        ico: '', // 网站图标
        description: '', // 网站描述
        keywords: '', // 网站关键字
        tools_code: '', // 工具代码
        visit_url: '' // PC商城访问链接
    }
    jumpDesc = '' // 跳转至空白页时是提示文字
    jumpLink = '' // 跳转至指定页时是页面链接

    // 表单验证
    formRules = {
        status: [
            {
                required: true,
                message: '请设置渠道状态',
                trigger: 'blur'
            }
        ],
        redirect_type: [
            {
                required: true,
                message: '请设置渠道关闭后访问方式',
                trigger: 'blur'
            }
        ]
    }

    $refs!: {
        formRef: any
    }
    /** E Data **/

    @Watch('jumpDesc', {
        immediate: true
    })
    getJumpDesc(val: any) {
        // 初始值
        this.form.redirect_content = val
    }
    @Watch('jumpLink', {
        immediate: true
    })
    getJumpLink(val: any) {
        // 初始值
        this.form.redirect_content = val
    }

    // 获取设置
    getPCSettings() {
        apiPCSettings()
            .then((res: any) => {
                this.form = res
            })
            .catch(() => {
                console.log('数据请求失败!')
            })
    }

    // 修改设置
    putPCSettingsSet() {
        this.$refs.formRef.validate((valid: boolean) => {
            // 预校验
            if (!valid) {
                return this.$message.error('请完善设置')
            }
            apiPCSettingsSet(this.form)
                .then((res: any) => {
                    this.getPCSettings()
                })
                .catch(() => {
                    console.log('数据请求失败!')
                })
        })
    }
    // 复制到剪贴板
    onCopy(value: string) {
        copyClipboard(value)
            .then(() => {
                this.$message.success('复制成功')
            })
            .catch(err => {
                this.$message.error('复制失败')
                console.log(err)
            })
    }

    /** S Life Cycle **/
    created() {
        this.getPCSettings()
    }
    /** E Life Cycle **/
}
